(function() {
    'use strict';

    angular.module("aerosApp")
        .controller("FiberGroupCtrl",FiberGroupController)
        .filter('featureAvailable', FeatureAvailable)
        .filter('isNewTool', IsNewTool);

    FiberGroupController.$inject = ["$rootScope", "$scope", "aerosApi", "$state", "$stateParams", "$timeout",
        'Notification', "editFiberGroupModal", "createFiberGroupModal", "newTestSetupModal", 'fiberTypes',
        'singleOltsTestDisplay', 'multiOltsTestDisplay', 'inspectionTestDisplay', '$uibModal', '$translate',
        'viewFileCollectionModal', 'FiberGroupService', 'AddEditToolModal', 'toolsDefinition', 'ToolService',
        'ToolsDefinitionService', 'storage', 'simpleFiberTypes', 'unmarkDeleteFiberGroupModal', 'HtmlHelperService',
        'routesConstant', 'instructions'];

    function FiberGroupController($rootScope, $scope, aerosApi, $state, $stateParams, $timeout, Notification, editFiberGroupModal,
              createFiberGroupModal, newTestSetupModal, fiberTypes, singleOltsTestDisplay, multiOltsTestDisplay,
              inspectionTestDisplay, $uibModal, $translate, viewFileCollectionModal, FiberGroupService, AddEditToolModal,
              toolsDefinition, ToolService, ToolsDefinitionService, storage, simpleFiberTypes,
              unmarkDeleteFiberGroupModal, HtmlHelperService, routesConstant, instructions) {

        var CUSTOMER = $scope.CUSTOMER = routesConstant.CUSTOMER;

        // This line clears our 'selected' FiberGroups (for updating multiple FiberGroups at a time with
        //  a tool or the like).
        // We may have had some selections from the last time the FiberGroups list was being displayed, we want to start fresh.
        FiberGroupService.selectedFiberGroups = [];

        $scope.$watch("projectReady", function (newVal) {
            if (!newVal) return;

            angular.extend($scope, {
                showUnmarkDeleteDialog: showUnmarkDeleteDialog,
                convertNewLines: HtmlHelperService.convertNewLines,
                FiberGroupService: FiberGroupService
            });

            $scope.fiberTypes = simpleFiberTypes.data;
            $scope.queryString = $stateParams.qry;

            loadTestSetups();
            loadTestLimits();

            $scope.formData = {
                selectToolLabel: ($scope.features.length ? "Select Type" : "No Tools Available"),
                selectedFeature: storage.get('project_selected_tool') || $scope.features[0] // inherited from common.js
            };

            $scope.license = $scope.$parent.accountRoles;

            function loadTestSetups() {
                aerosApi.getTestSetups($scope.organization.id)
                    .then(function (setups) {
                        $scope.testSetups = _.filter(setups, function (setup) {
                            return _.includes($scope.features, setup.type.toLowerCase());
                        });
                    });
                fetchAppliedTools();
                $scope.orgTools = {};
                $scope.projectTools = {};
                $scope.projectToolsByUuid = {};
                _.each($scope.features, function (featureType) {
                    var config = {
                        toolType: featureType,
                        orgId: $scope.orgId,
                        projectId: $scope.projectId
                    };
                    if (_.includes(['otdr'], featureType)) {
                        fetchTools("Org", featureType).then(function (tools) {
                            $scope.orgTools[featureType] = tools;
                        });
                        fetchTools("Project", featureType).then(function (tools) {
                            $scope.projectTools[featureType] = tools;
                            _.each(tools, function (tool) {
                                $scope.projectToolsByUuid[tool.uuid] = tool;
                            });
                        });
                    }
                });
            }

            function fetchTools(level, type) {
                // levels: "Project", "Org"
                // types: "olts", "certification", "inspection", "otdr"
                var config = {
                    toolType: type.toLowerCase(),
                    orgId: $scope.orgId,
                    projectId: $scope.projectId
                };
                var method = "get" + level + "ToolsByType";
                return aerosApi[method](config).then(function (r) {
                    return r.data;
                }).then(function (tools) {
                    if (angular.isArray(tools)) {
                        return tools.map(removeJsonPrefixes);
                    } else if (tools.tools && angular.isArray(tools.tools)) {
                        return tools.tools.map(removeJsonPrefixes);
                    }
                }, function (err) {
                    Notification.error(err.data.detail + " for get " + config.toolType);
                }).then(function (tools) {
                    return tools;
                });
            }

            // TODO: DRY with ToolLibraryService
            function removeJsonPrefixes(item) {
                return _.mapKeys(item, function (v, k) {
                    return k.replace('json', '');
                });
            }

            function fetchAppliedTools() {
                return aerosApi.getToolsAppliedToProject({
                    orgId: $scope.orgId,
                    projectId: $scope.projectId
                }).then(function (rtn) {
                    return $scope.appliedTools = rtn.data;
                }, function (err) {
                    Notification.error("There was a problem fetching tools applied to this project.");
                    return err;
                });
            }

            $scope.typeIsSelected = function (setup) {
                if (typeof setup === "string") {
                    setup = {type: setup};
                }
                return (setup.type.toLowerCase() === $scope.formData.selectedFeature);
            };

            function loadTestLimits() {
                aerosApi.getTestLimits($scope.organization.id)
                    .then(function (response) {
                        $scope.testLimits = response.limits.filter(function(item) {
                            return !item.obsolete;
                        });
                    });

            }

            $scope.messages = {
                "removeSuccess": "Removed fiber groups",
                "createTestSuccess": "Test Setup created.",
                "createTestFailure": "Error creating test setup.",
                "createFibergroupSuccess": ["Fiber group ", " added"],
                "createFibergroupError": "Error adding",
                "duplicateNameError": ["Fiber group ", " already exists"],
                "addTestToFibergroupSuccess": ["Successfully added Test Setup", "to", "Fiber groups"],
                "addTestToFibergroupFail": {
                    "FiberModeMismatchException": ["Could not add Test Setup", "to Fiber Group", "because it is not applicable to", "fibers"],
                    "FiberTypeMismatchException": ["Could not add Test Setup", "to Fiber Group", "because it has a test limit that is not applicable to", "fibers"],
                    "TestSetupAlreadyAddedException": ["Test Setup", "has already been added to Fiber Group"],
                    "TestSetupTypeAlreadyAddedException": ["Could not add Test Setup", "because a test of that type has already been added to Fiber Group"]
                },
                "FibergroupExists": "Fiber group with this name already exists in the project",
                "updateSuccess": "Updated",
                "attachFileSuccess": "Successfully attached the file",
                "attachFileError": "Error attaching the file"
            };
            _.each($scope.messages, function (message, key) {
                if (typeof message === "string") {
                    $translate(message).then(function (translation) {
                        $scope.messages[key] = translation;
                    });
                } else {
                    _.each(message, function (messagePart, k) {
                        $translate(messagePart).then(function (translation) {
                            $scope.messages[key][k] = translation;
                        });
                    });
                }
            });

            $scope.hasSelectedFiberGroups = function (fiberMode, fiberType, testType, testSetupGeneral) {
                if (testSetupGeneral && testSetupGeneral.orlTestLimitKey) {
                    var matchingOrlLimit = _.find($scope.testLimits, function (testLimit) {
                        return testSetupGeneral.orlTestLimitKey === testLimit.key;
                    });
                    if (matchingOrlLimit && matchingOrlLimit.fiberType) {
                        var mismatchedFiberGroup = _.find(FiberGroupService.selectedFiberGroups, function (fiberGroup) {
                            return fiberGroup.fiberType !== matchingOrlLimit.fiberType;
                        });
                        if (mismatchedFiberGroup) {
                            return false; // don't show ORL test if any selected FG fail to match fiberType of ORL limit
                        }
                    }
                }
                if (fiberMode) {
                    var testTypeCount = 0;
                    //test to see if they already have an OLTS or Cert test assigned
                    //they can have either OLTS or Cert, but not both
                    if (testType === 'OLTS' || testType === 'Certification') {
                        _.forEach(FiberGroupService.selectedFiberGroups, function (fiberGroup) {
                            _.forEach(fiberGroup.testSetups, function (testSetup) {
                                if (testSetup.type === 'OLTS') {
                                    testTypeCount = testTypeCount + 1;
                                }
                            });
                        });

                        _.forEach(FiberGroupService.selectedFiberGroups, function (fiberGroup) {
                            _.forEach(fiberGroup.testSetups, function (testSetup) {
                                if (testSetup.type === 'Certification') {
                                    testTypeCount = testTypeCount + 1;
                                }
                            });
                        });

                    } else if (testType === 'INSPECTION') {
                        //test to see if they have an EFI test already assigned, they can only assign one
                        _.forEach(FiberGroupService.selectedFiberGroups, function (fiberGroup) {
                            _.forEach(fiberGroup.testSetups, function (testSetup) {
                                if (testSetup.type === 'INSPECTION') {
                                    testTypeCount = testTypeCount + 1;
                                }
                            })
                        })
                    }

                    var fiberModeMatches = _.filter(FiberGroupService.selectedFiberGroups, _.matches({'fiberMode': fiberMode}));
                    var fiberTypeMatches = _.filter(FiberGroupService.selectedFiberGroups, _.matches({'fiberType': fiberType}));
                    return testTypeCount === 0 && fiberModeMatches.length === FiberGroupService.selectedFiberGroups.length && (!fiberType || (fiberType && fiberTypeMatches.length === FiberGroupService.selectedFiberGroups.length));
                } else {
                    return FiberGroupService.selectedFiberGroups.length > 0;
                }
            };

            $scope.isToolDisabledToSelectedFiberGroups = function (toolType, testSetup) {
                return _.some(FiberGroupService.selectedFiberGroups, function (group) {
                    return $scope.appliedTools[group.uuid] &&
                        _.some($scope.appliedTools[group.uuid], function (tool) {
                            return tool.toolType === toolType;
                        }) || testSetup.fiberMode !== group.fiberMode;
                });
            };

            $scope.isSelected = function (fiberGroup) {
                var ids = _.map(FiberGroupService.selectedFiberGroups, 'id');
                return _.includes(ids, fiberGroup.id);
            };

            $scope.selectAll = function selectAll() {
                FiberGroupService.selectedFiberGroups = $scope.project.fiberGroups;
            };

            $scope.selectNone = function selectNone() {
                FiberGroupService.selectedFiberGroups = [];
            };

            var mapSelectedFeature = {
                "certification": "Certification",
                "inspection": "Efi",
                // "inspection": "Inspection",
                "olts": "OLTS",
                "otdr": "OTDR"
            };
            if ($scope.allow["configureProjects"]) {
                $scope.toggleSelected = function (fiberGroup) {
                    if ($scope.isSelected(fiberGroup)) {
                        FiberGroupService.selectedFiberGroups = _.filter(FiberGroupService.selectedFiberGroups, function (fg) {
                            return fg.id !== fiberGroup.id;
                        });
                    }
                    else {
                        FiberGroupService.selectedFiberGroups.push(fiberGroup);
                    }
                };
                $scope.deselectAllFiberGroups = function () {
                    FiberGroupService.selectedFiberGroups = [];
                };
                $scope.deleteSelectedFiberGroups = function () {
                    if (!$scope.allow["configureProjects"]) return;
                    $uibModal.open({
                        size: "md",
                        templateUrl: '/static/templates/dashboard/project/modal/confirmDeleteModal.html',

                        controller: ["$scope", "selectedFiberGroups", function ($scope, selectedFiberGroups) {
                            FiberGroupService.selectedFiberGroups = selectedFiberGroups;
                            $scope.entity = "Selected Fiber Groups";
                            $scope.list = selectedFiberGroups;
                            $scope.warning = "This will permanently delete selected fiber groups in the project."
                        }],
                        resolve: {
                            selectedFiberGroups: function () {
                                return FiberGroupService.selectedFiberGroups;
                            }
                        }
                    }).result
                        .then(function () {
                            var ids = _.map(FiberGroupService.selectedFiberGroups, 'id');
                            return aerosApi.deleteFiberGroups(ids, $scope.organization.id)
                                .then($scope.updateFGsAfterDelete)
                                .then(function (response) {
                                    $scope.deselectAllFiberGroups();
                                    Notification.success($scope.messages['removeSuccess']);
                                });
                        });
                };


                $scope.basicUser = function () {
                    return $scope.license != "Professional";
                };
                $scope.legacyToolSelected = function () {
                    return !_.includes(['otdr'], $scope.formData.selectedFeature);
                };
                $scope.createSetupByMode = function (mode, $event) {
                    if (!mapSelectedFeature[$scope.formData.selectedFeature]) {
                        return alert('create ' + mode + " " + $scope.formData.selectedFeature + " project tool instance");
                    }

                    $scope.createTestSetup({
                        type: mapSelectedFeature[$scope.formData.selectedFeature],
                        fiberMode: mode
                    }, $event);
                };

                $scope.createTestSetup = function (testType, $event) {
                    if (['OTDR'].indexOf(testType.type) >= 0) {
                        $scope.createProjectTool(testType)
                    } else {
                        if (!$scope.allow["createTests"]) return;
                        newTestSetupModal.open(testType, {}).then(function (response) {
                            if (response.code === 500) {
                                Notification.error($scope.messages['createTestFailure']);
                            } else {
                                Notification.success($scope.messages['createTestSuccess']);
                                loadTestSetups();
                            }
                        });
                    }
                };
                $scope.duplicateSelectedFiberGroups = function (fiberGroup) {
                    if (!$scope.allow["configureProjects"]) return;
                    var ids = _.map(FiberGroupService.selectedFiberGroups, 'id');
                    if (ids.length && !$scope.copyFgProgress) {
                        $scope.copyFgProgress = true;
                        aerosApi.duplicateFiberGroups(ids, $scope.organization.id)
                            .then($scope.updateFGsAfterCopy)
                            .then(function () {
                                $scope.copyFgProgress = false;

                                /*$scope.loadProject()
                                    .then(fetchAppliedTools)
                                    .then(function () {
                                        $scope.copyFgProgress = false;
                                    })
                                    .catch(function () {
                                        $scope.copyFgProgress = false;
                                    });*/
                            });
                    }
                };
                $scope.generateTestResults = function () {
                    if (!$scope.allow["configureProjects"]) return;
                    var ids = _.map(FiberGroupService.selectedFiberGroups, 'id');
                    if (ids.length) {
                        aerosApi.generateTestResults(ids, $scope.organization.id).success(function () {
                            $scope.loadProject();
                        });
                    }
                };
                $scope.removeTestSetupFromFiberGroup = function (testSetup, fiberGroup) {
                    if (!$scope.allow["configureProjects"] || $scope.removingTestSetupFromFiberGroup) return;
                    $scope.removingTestSetupFromFiberGroup = true;
                    aerosApi.removeTestSetupFromFiberGroup(testSetup, fiberGroup, $scope.organization.id)
                        .success(function (data) {

                            Notification.success(
                                "Successfully removed Test Setup '" +
                                testSetup.name +
                                "' from '" +
                                fiberGroup.name +
                                "'"
                            );

                            //search for the testSetup in the fibergroup loaded and remove it
                            for (var i = 0; i < fiberGroup.testSetups.length; i++) {
                                if (fiberGroup.testSetups[i].key === testSetup.key) {
                                    fiberGroup.testSetups.splice(i, 1);
                                }
                            }

                            //also remove it in the selected fiber groups var
                            //needed to keep the icons for selectable test setups updated to match after the removal
                            _.forEach(FiberGroupService.selectedFiberGroups, function (selectedGroup) {
                                if (selectedGroup.id === fiberGroup.id) {
                                    for (var i = 0; i < selectedGroup.testSetups.length; i++) {
                                        if (selectedGroup.testSetups[i].key === testSetup.key) {
                                            selectedGroup.testSetups.splice(i, 1);
                                        }
                                    }
                                }
                            });
                            $scope.loadProject();
                            $scope.removingTestSetupFromFiberGroup = false;

                        })
                        .error(function (error) {
                            Notification.error("There was a problem removing Test Setup '" + testSetup.name + "'. "
                                + (error && error.message ? error.message : ""));
                            $scope.removingTestSetupFromFiberGroup = false;
                        });
                };
                $scope.removeToolFromFiberGroup = function (tool, fiberGroup) {
                    if (!$scope.allow["configureProjects"]) return;
                    var config = {
                        orgId: $scope.organization.id,
                        projectId: $scope.projectId,
                        toolType: tool.toolType,
                        toolId: tool.toolUuid,
                        fiberGroupsArray: [fiberGroup.id]
                    };
                    $scope.$parent.$parent.clicked = true;
                    $scope.$parent.$parent.arrayRemovedTools.push($scope.$parent.$parent.arrayRemovedTools.length);
                    aerosApi.removeProjectToolFromFiberGroupsByToolType(config).then(function (rtn) {
                        $scope.$parent.$parent.arrayRemovedTools.splice(1);
                        Notification.success("Successfully removed the tool from selected media.");
                        fetchAppliedTools();
                    }, function (err) {
                        Notification.error("There was a problem removing the tool from selected media.");
                    });
                };
                $scope.showCreateFiberGroupModal = function (fiberType) {
                    if (!$scope.allow["configureProjects"]) return;
                    createFiberGroupModal.open(fiberType).then(function (fiberGroup) {


                        if (fiberGroup.fiberMode === 'Multimode') {
                            fiberGroup.fiberType = fiberGroup.subFiberType.name;
                        }

                        aerosApi.createFiberGroup(fiberGroup, $scope.projectId, $scope.organization.id)
                            .then($scope.updateFGsAfterAdd)
                            .then(function (response) {
                                if (response.data.statusCode.code === 500) {
                                    Notification.error($scope.messages['createFibergroupError'] + " '" + fiberGroup.name + "'");
                                } else if (response.data.statusCode.code === 0) {
                                    var arr = [];
                                    var names = fiberGroup.name.split("\n");
                                    for (var i = 0; i < names.length; i++) {
                                        if (!arr.includes(names[i])) arr.push(names[i]);
                                    }
                                    Notification.success($scope.messages['createFibergroupSuccess'][0] + "'" + arr + "'" + $scope.messages['createFibergroupSuccess'][1]);
                                }
                                else {
                                    Notification.success($scope.messages['createFibergroupSuccess'][0] + "'"
                                        + response.data.newlyCreatedFiberGroups.map(function(item){
                                            return item.name;
                                        }).join(', ') + "'" + $scope.messages['createFibergroupSuccess'][1]);
                                }
                            }, function (response) {
                                if (response.data.statusCode.code === 500) {
                                    var names = fiberGroup.name.split("\n");
                                    Notification.error($scope.messages['duplicateNameError'][0] + " '" + names + "' " + $scope.messages['duplicateNameError'][1]);
                                } else {
                                    Notification.error($scope.messages['createFibergroupError'] + " '" + names + "'");
                                }
                            });
                    });
                };
                $scope.addTestSetupToSelectedFiberGroups = function (testSetup) {
                    if (!$scope.allow["configureProjects"]) return;
                    var ids = _.map(FiberGroupService.selectedFiberGroups, 'id');
                    if (ids.length === 0) {
                        Notification.error("Please select Fiber Group before applying a tool");
                    } else {
                        aerosApi.addTestSetupToFiberGroups(testSetup, ids, $scope.organization.id)
                            .then(function (results) {
                                $scope.loadProject();
                                _.forEach(FiberGroupService.selectedFiberGroups, function (fiberGroup) {
                                    fiberGroup.testSetups.push(testSetup);
                                });
                                if (results.data.results.left > 0) {
                                    Notification.success(
                                        "Successfully added Test Setup '" +
                                        testSetup.name +
                                        "' to " +
                                        results.data.results.left +
                                        " Fiber groups."
                                    );
                                } else {
                                    Notification.success(
                                        "Successfully added Test Setup '" +
                                        testSetup.name + "'");
                                }
                                if (results.data.results.errors.length > 0) {
                                    angular.forEach(results.data.results.errors, function (error) {
                                        if (error.type === 'FiberModeMismatchException') {
                                            Notification.error(
                                                $scope.messages['addTestToFibergroupFail']["FiberModeMismatchException"]["Could not add Test Setup"] +
                                                " '" + testSetup.name + "' " +
                                                $scope.messages['addTestToFibergroupFail']["FiberModeMismatchException"]["to Fiber Group"] +
                                                " '" + error.fiberGroup.name + "' " +
                                                $scope.messages['addTestToFibergroupFail']["FiberModeMismatchException"]["because it is not applicable to"] +
                                                " " + error.fiberGroup.fiberMode + " " +
                                                $scope.messages['addTestToFibergroupFail']["FiberModeMismatchException"]["fibers"] +
                                                "."
                                            );
                                        } else if (error.type === 'FiberTypeMismatchException') {
                                            Notification.error(
                                                $scope.messages['addTestToFibergroupFail']["FiberTypeMismatchException"]["Could not add Test Setup"] +
                                                "' " + testSetup.name + "' " +
                                                $scope.messages['addTestToFibergroupFail']["FiberTypeMismatchException"]["to Fiber Group"] +
                                                " '" + error.fiberGroup.name + "' " +
                                                $scope.messages['addTestToFibergroupFail']["FiberTypeMismatchException"]["because it has a test limit that is not applicable to"] +
                                                " " + error.fiberGroup.fiberType + " " +
                                                $scope.messages['addTestToFibergroupFail']["FiberTypeMismatchException"]["fibers"] +
                                                "."
                                            );
                                        } else if (error.type === 'TestSetupAlreadyAddedException') {
                                            Notification.error(
                                                $scope.messages['addTestToFibergroupFail']["TestSetupAlreadyAddedException"]["Test Setup"] +
                                                " '" + testSetup.name + "' " +
                                                $scope.messages['addTestToFibergroupFail']["TestSetupAlreadyAddedException"]["has already been added to Fiber Group"] +
                                                " '" + error.fiberGroup.name + "'."
                                            );
                                        } else if (error.type === 'TestSetupTypeAlreadyAddedException') {
                                            Notification.error(
                                                $scope.messages['addTestToFibergroupFail']["TestSetupTypeAlreadyAddedException"]["Could not add Test Setup"] +
                                                " '" + testSetup.name + "' " +
                                                $scope.messages['addTestToFibergroupFail']["TestSetupTypeAlreadyAddedException"]["because a test of that type has already been added to Fiber Group"] +
                                                " '" + error.fiberGroup.name + "'."
                                            );
                                        }
                                    });
                                }
                            }, function(error) {
                                if (error && error.data) {
                                    Notification.error(error.data);
                                }
                            });
                    }

                };
                $scope.applyProjectToolToSelectedFiberGroups = function (toolType, tool) {
                    if (!$scope.allow["configureProjects"] ||
                        $scope.isToolDisabledToSelectedFiberGroups(toolType, tool)) {
                        return;
                    }

                    var ids = _.map(FiberGroupService.selectedFiberGroups, 'id');
                    if (ids.length === 0)
                        Notification.error("Please select Fiber Group before applying a tool");
                    else {
                        var config = {
                            orgId: $scope.orgId,
                            projectId: $scope.projectId,
                            toolType: toolType,
                            fiberGroupsArray: ids,
                            toolId: tool.uuid
                        };
                        aerosApi.applyProjectToolToFiberGroupsByToolType(config).then(function (data) {
                            if (!(data.data.NoFiberGroupSelected === "FAIL")) {
                                Notification.success("Successfully applied the tool to selected fibergroups.");
                                fetchAppliedTools();
                            } else {
                                Notification.error("Please select fiber Group before applying OTDR tool to it.");
                            }
                        }, function (err) {
                            console.log(err);

                            if (err.status === 409 && angular.isString(err.data)) {
                                Notification.error(err.data);
                            } else {
                                Notification.error("There was a problem applying the tool to selected fibergroups.");
                            }

                        });

                    }
                };

                $scope.saveToToolbeltAndApplyToFibergroup = function (toolType, tool) {
                    return $scope.isToolDisabledToSelectedFiberGroups(toolType, tool) ?
                        null :
                        $scope.saveOrgToolAsProjectTool(toolType, tool).then(function (newTool) {
                            $scope.applyProjectToolToSelectedFiberGroups(toolType, newTool);
                        });
                };


                $scope.saveOrgToolAsProjectTool = function (toolType, tool) {
                    return AddEditToolModal.open(tool, toolsDefinition.data[mapSelectedFeature[toolType]],
                        undefined, AddEditToolModal.modes.ADDPROJECT, $scope.orgId,
                        $scope.projectId).then(function (rtn) {
                        Notification.success("Successfully created the project tool instance.");
                        return fetchTools("Project", toolType).then(function (tools) {
                            $scope.projectTools[toolType] = tools;
                            return tools.find(function (t) {
                                return t.name === rtn.name;
                            });
                        }).catch(function (err) {
                            if(err === 'cancel' || err === 'close'){
                                return;
                            }
                            Notification.error("There was a problem updating the tool list");
                        });
                    });
                };
                $scope.promoteProjectToolToOrgtool = function (toolType, tool) {
                    AddEditToolModal.open(tool, toolsDefinition.data[mapSelectedFeature[toolType]],
                        undefined, AddEditToolModal.modes.ADD, $scope.orgId).then(function (rtn) {
                        Notification.success("Successfully created the org tool template.");
                        fetchTools("Org", toolType).then(function (tools) {
                            $scope.orgTools[toolType] = tools;
                        }, function (err) {
                            Notification.error("There was a problem updating the tool list");
                        });
                    }, function (err) {
                        if(err === 'cancel' || err === 'close'){
                            return;
                        }
                        console.log(err);
                        Notification.error("There was a problem creating the org tool template.");
                    });
                };

                $scope.editProjectTool = function (toolType, toolInstance) {
                    openEditToolModal(toolType, toolInstance).then(function (res) {
                        return res;
                    }).then(openConfirmInvalidateModalIfNeeded(toolType))
                        .then(fetchAppliedTools).then(function () {
                        Notification.success("Successfully updated the project tool instance.");
                        fetchTools("Project", toolType).then(refreshTools(toolType), handleFetchToolsError);
                    });
                };

                var openConfirmInvalidateModalIfNeeded = function openConfirmInvalidateModalIfNeeded(toolType) {
                    return function (result) {
                        if (result.error) {
                            return result.error.data.length ?
                                openConfirmInvalidateModal(toolType, result.tool, result.error.data)
                                    .then(_.partial(updateTool, toolType, result.tool)) : undefined;
                        }
                    }
                };

                var openConfirmInvalidateModal = function openConfirmInvalidateModal(toolType, tool, affected) {
                    return $uibModal.open({
                        size: "md",
                        templateUrl: '/static/templates/dashboard/project/modal/confirmInvalidateModal.html',
                        controller: ["$scope", function ($scope) {
                            $scope.warning = "Warning text";
                            $scope.entity = toolType + " tool instance";
                            $scope.list = getAffectedFiberGroups(affected);
                        }]
                    }).result;
                };

                var updateTool = function updateTool(toolType, tool) {
                    return ToolService.updateTool(toolType, $scope.orgId, $scope.projectId, {tool: tool}, true);
                };

                var refreshTools = function refreshTools(toolType) {
                    return function (tools) {
                        $scope.projectTools[toolType] = tools;
                    }
                };

                var handleFetchToolsError = function handleFetchToolsError(err) {
                    Notification.error("There was a problem updating the tool list");
                };

                var openEditToolModal = function openEditToolModal(toolType, tool) {
                    var definition = toolsDefinition.data[mapSelectedFeature[toolType]];
                    var mode = AddEditToolModal.modes.EDITPROJECT;
                    return AddEditToolModal.open(tool, definition, undefined, mode, $scope.orgId, $scope.projectId);
                };

                $scope.copyProjectTool = function (toolType, tool) {
                    var newTool = angular.copy(tool);
                    newTool.name = "Copy of " + newTool.name;
                    AddEditToolModal.open(newTool, toolsDefinition.data[mapSelectedFeature[toolType]],
                        undefined, AddEditToolModal.modes.ADDPROJECT, $scope.orgId, $scope.projectId)
                        .then(function (rtn) {
                            Notification.success("Successfully created the project tool instance.");
                            fetchTools("Project", toolType).then(function (tools) {
                                $scope.projectTools[toolType] = tools;
                            }, function (err) {
                                Notification.error("There was a problem updating the tool list");
                            });
                        }, function (err) {
                            if(err === 'cancel' || err === 'close'){
                                return;
                            }
                            console.log(err);
                            Notification.error("There was a problem creating the project tool instance.");
                        });
                };

                $scope.createProjectTool = function (testType) {
                    if (!$scope.features.length) {
                        alert('You have no tools available for your organization');
                        return;
                    }
                    var toolModel = testType ? {fiberMode: testType.fiberMode, toolType: testType.type} : {};
                    var toolType = $scope.formData.selectedFeature;

                    AddEditToolModal.open(toolModel /*new tool*/, toolsDefinition.data[mapSelectedFeature[toolType]],
                        undefined, AddEditToolModal.modes.ADDPROJECT, $scope.orgId, $scope.projectId)
                        .then(function (rtn) {
                            Notification.success("Successfully created the project tool instance.");
                            fetchTools("Project", toolType)
                                .then(function (tools) {
                                    $scope.projectTools[toolType] = tools;

                                }, function (err) {
                                    Notification.error("There was a problem updating the tool list");
                                });
                        });
                };

		$scope.fgCompare = function(a, b) {
			// see docs on custom comparators for
			// https://docs.angularjs.org/api/ng/filter/orderBy#under-the-hood
			// separate strings into arrays of contiguous digits
			// and non-digits
			var aParts = a.value.toString().match(/[\d]+|[^\d]+/gi);
			var bParts = b.value.toString().match(/[\d]+|[^\d]+/gi);
			for(var i = 0; i < aParts.length; i++) {
				// check to make sure we won't overrun b
				if(i < bParts.length) {
					if(aParts[i] != bParts[i]) {
						// these parts are different
						// so return the naturl
						// or numeric sort order
						if(!isNaN(aParts[i]) && !isNaN(bParts[i])) {
							// both are numbers
							var diff = parseInt(aParts[i],10) - parseInt(bParts[i], 10);
							return (diff == 0 ? aParts[i].localeCompare(bParts[i]) : diff);
						} else {
							// at least one is not a number
							// so just use the natural string comparator
							return aParts[i].localeCompare(bParts[i]);
						}
					} else {
						// so far they are the same
						continue;
					}
				} else {
					// strings are the same through the
					// length of b so a sorts after
					return 1;
				}
			}
			// the strings are the same through the length of a
			// or they are identical so let the nature comparator
		        return a.value.toString().localeCompare(b.value.toString());
		}

                $scope.deleteProjectTool = function (toolType, tool) {
                    deleteTool(toolType, tool, false).then(function (res) {
                        return res.data;
                    }).then(getAffectedFiberGroups).then(_.curry(openConfirmDeleteModal)(toolType, tool)).then(_.partial(deleteTool, toolType, tool, true)).then(function () {
                        Notification.success("Successfully deleted project tool instance.");
                    }, function (err) {
                        if(err == 'close' || err == 'cancel'){
                            return;
                        }
                        Notification.error("There was a problem deleting the project tool instance.");
                    }).then(_.partial(reloadTools, toolType)).then(fetchAppliedTools);
                };

                var reloadTools = function reloadTools(toolType) {
                    return fetchTools("Project", toolType).then(function (tools) {
                        $scope.projectTools[toolType] = tools;
                    });
                };

                var deleteTool = function deleteTool(toolType, tool, overwrite) {
                    var config = {
                        orgId: $scope.orgId,
                        projectId: $scope.projectId,
                        toolType: toolType.toLowerCase(),
                        toolId: tool.uuid,
                        overwrite: overwrite
                    };
                    return aerosApi.deleteProjectToolByType(config);
                };

                var openConfirmDeleteModal = function openConfirmDeleteModal(toolType, tool, affected) {
                    return $uibModal.open({
                        size: "md",
                        templateUrl: '/static/templates/dashboard/project/modal/confirmDeleteModal.html',
                        resolve: {
                            toolType: function () {
                                return toolType;
                            },
                            tool: function () {
                                return tool;
                            }
                        },
                        controller: ["$scope", "toolType", "tool", function ($scope, toolType, tool) {
                            $scope.entity = toolType + " tool instance";
                            $scope.list = [tool];
                            $scope.affected = affected;
                            $scope.warning = "This will permanently delete the tool, and invalidate any results that have been collected.";
                        }]
                    }).result;
                };

                var getAffectedFiberGroups = function getAffectedFiberGroups(groups) {
                    var groupsIds = _.map(groups, 'fiberGroupUuid');
                    return $scope.project.fiberGroups.filter(function (fg) {
                        return _.includes(groupsIds, fg.uuid);
                    });
                };

                $scope.editFiberGroup = function (fiberGroup) {
                    if (!$scope.allow["configureProjects"]) return;
                    editFiberGroupModal.open(fiberGroup).then(function (updatedFiberGroup) {
                        updatedFiberGroup.helixFactor = +updatedFiberGroup.helixFactor;

                        if (updatedFiberGroup.fiberMode === "Multimode") {
                            updatedFiberGroup.fiberType = updatedFiberGroup.subFiberType.name;
                        }

                        aerosApi.saveFiberGroup(updatedFiberGroup, $scope.projectId, $scope.organization.id)
                            .success(function (data) {
                                if (data.code === 1001) {
                                    Notification.error($scope.messages["FibergroupExists"]);
                                } else {

                                    (function updateFiberGroupsWithUpdatedFG(updatedFiberGroup) {
                                        $scope.project.fiberGroups.forEach(function(item) {
                                            if (item.id === updatedFiberGroup.id) {
                                                for(var key in updatedFiberGroup) {
                                                    if (updatedFiberGroup.hasOwnProperty(key)) {
                                                        item[key] = updatedFiberGroup[key];
                                                        if (key === 'fiberType' && updatedFiberGroup[key] === 'MMF (OM4)') {
                                                            item[key] = 'MMF';
                                                        }
                                                    }
                                                }
                                            }
                                        })
                                    })(updatedFiberGroup);

                                    $scope.project.fiberGroups.find(function(item) {
                                        return item.id
                                    });

                                    Notification.success(
                                        $scope.messages["updateSuccess"] +
                                        " '" + updatedFiberGroup.name + "'"
                                    );
                                }
                            })
                            .error(function(response) {
                                Notification.error(response.data);
                            });
                    });
                };
                $scope.deleteFiberGroup = function (fiberGroup) {
                    if (!$scope.allow["configureProjects"]) return;
                    aerosApi.deleteFiberGroup(fiberGroup, $scope.organization.id)
                        .then($scope.updateFGsAfterDelete);
                };


                $scope.toolChanged = function () {
                    storage.set('project_selected_tool', $scope.formData.selectedFeature);
                };
            }

            $scope.showToolDetails = function (toolType, tool) {
                AddEditToolModal.open(tool, toolsDefinition.data[mapSelectedFeature[toolType]],
                    undefined /*presets*/, AddEditToolModal.modes.VIEW, $scope.orgId);
            };
            $scope.showFiberGroupInfo = function (fiberGroup) {
                var dummyConnector = {
                    connectorType: "None",
                    linkType: "None"
                };
                if (!fiberGroup.end1Connector) {
                    fiberGroup.end1Connector = dummyConnector;
                    fiberGroup.end2Connector = dummyConnector;
                }
                $uibModal.open({
                    size: "md",
                    templateUrl: 'static/templates/dashboard/project/modal/fiberGroupInfoModal.html',
                    controller: ["$scope", "fiberGroup", "HtmlHelperService",
                        function ($scope, fiberGroup, HtmlHelperService) {

                            angular.extend($scope, {
                                fiberGroup: fiberGroup,
                                convertNewLines: HtmlHelperService.convertNewLines
                            });

                        }],
                    resolve: {
                        fiberGroup: function () {
                            return fiberGroup;
                        }

                    }
                });
            };
            $scope.showTestResults = function () {
                $state.go(CUSTOMER.PROJECTS_PROJECT_RESULTS);
            };

            $scope.updateFilter = function () {
                FiberGroupService.selectedFiberGroups = [];
            };

            $scope.showDetailedResult = function (fiberGroup, resultType) {
                $state.go(CUSTOMER.PROJECTS_PROJECT_RESULTS_ALL.stateName, {
                    id: $scope.project.id,
                    testType: "fibergroup",
                    resultType: mapRTtoCurrentDetail[resultType],
                    qry: "id=" + fiberGroup.uuid
                });
            };

            $scope.getProjectToolByToolId = function(tool) {
                return $scope.projectTools[tool.toolType].find(function(prTool) {
                    return prTool.uuid === tool.toolUuid;
                });
            };

            var mapRTtoCurrentDetail = {
                "All": "all",
                "PassedOrComplete": "complete",
                "Failed": "failed-any",
                "Incomplete": "incomplete"
            };

            var directions = ['Unidirectional', 'Bidirectional'];
            $scope.directions = {};
            _.each(directions, function (dir) {
                $translate(dir).then(function (translation) {
                    $scope.directions[dir] = translation;
                });
            });
            var testTypeNames = {
                "OLTS": "Loss",
                "Certification": "Loss Certification",
                "INSPECTION": "Inspect",
                "ORL": "Orl"
            };

            $scope.testTypeNames = {};
            _.each(testTypeNames, function (val, key) {
                $translate(val).then(function (translation) {
                    $scope.testTypeNames[key] = translation;
                });
            });

            $scope.showTestDetails = function (testSetup) {
                $uibModal.open({
                    size: "md",
                    templateUrl: '/static/templates/dashboard/project/modal/testSetupInfoModal.html',
                    resolve: {
                        testSetup: function () {
                            return testSetup;
                        },
                        config: ['configService', function (configService) {
                            return configService.getPromise();
                        }]
                    },
                    controller: ["$scope", "testSetup", "config", function ($scope, testSetup, config) {
                        if ($scope.testSetupDisplay !== undefined) {
                            $scope.testSetupDisplay.length = 0;
                        }
                        if (testSetup.type === 'INSPECTION') {
                            $scope.testSetupDisplay = angular.copy(inspectionTestDisplay);
                        } else if (testSetup.fiberMode === 'Singlemode') {
                            $scope.testSetupDisplay = angular.copy(singleOltsTestDisplay);
                        } else {
                            $scope.testSetupDisplay = angular.copy(multiOltsTestDisplay);
                        }

                        var testMethods = config.getTestMethods();

                        _.each(testSetup, function (value, key) {
                            //console.log("key: "+key+ ", value="+value);
                            var disp = _.find($scope.testSetupDisplay, {'id': key});
                            if (typeof disp !== 'undefined') {
                                if (disp.type === "date") {
                                    disp.value = new Date(value).toLocaleString();
                                } else {
                                    disp.value = value;
                                }

                                if (disp.id === 'testMethod') {
                                    var testMethod = testMethods.find(function (item) {
                                        return item.value === disp.value;
                                    });

                                    if (testMethod) {
                                        disp.value = testMethod.label;
                                    }
                                }
                            }


                        });
                    }]
                });
            };

            $scope.formatTestSetup = function (testSetup) {
                var direction = testSetup.direction ? $scope.directions[testSetup.direction] + ": " : "";
                var name = $scope.testTypeNames[testSetup.type];
                var rule = testSetup.rule ? " @ " + testSetup.rule : "";
                return direction + name + rule;
            };


            $scope.testTypeIconName = function (testSetup) {
                var testTypeIcons = {
                    "OLTS": "loss",
                    "Certification": "loss",
                    "INSPECTION": "inspect",
                    "ORL": "orl"
                };
                var directions = {
                    "Unidirectional": "unidirectional",
                    "Bidirectional": "bidirectional"
                };
                var iconName = testTypeIcons[testSetup.type];
                if (testSetup.direction) {
                    iconName += "-" + directions[testSetup.direction];
                }
                if (testSetup.rule) {
                    iconName += "-cert";
                }
                return iconName;
            };
            $scope.deselectProjects();
            $scope.reveal = $rootScope.userSettings.showProgress;
            $scope.toggleReveal = function () {
                $scope.reveal = !$scope.reveal;
                $rootScope.userSettings.showProgress = $scope.reveal;
            };
            $scope.placeTooltip = function () {
                $timeout(function () {
                    $('.tooltip').css("top", "-260px");
                    $('.tooltip-arrow').css("top", "95%");
                }, 0);
            };

            $scope.viewFileCollection = function (level, obj, fiberGroup) {
                var fiberCount = fiberGroup ? fiberGroup.fiberCount : undefined;

                if (level === "Fibergroup") {
                    fiberCount = fiberCount || $scope.fiberCount;

                    var fibers = fiberCount ? new Array(fiberCount) : [];

                    _.each(fibers, function (noop, counter) {
                        var i = counter + 1;
                        var orig = _.find(obj.fibers, function (f) {
                            return Number(f.fiberId) === i;
                        });
                        fibers[counter] = orig ? orig : {fiberId: i, attachments: []};
                    });
                    obj.fibers = fibers;
                }

                var configObj = {
                    project: $scope.project,
                    linkLevels: [level.toLowerCase()],
                    obj: obj,
                    level: level,
                    orgId: $scope.organization.id,
                    parent: level === 'Project' ? $scope.project : fiberGroup,
                    refresh: $scope.loadProject
                };
                viewFileCollectionModal.open(configObj);
            };
            $scope.createFileCollection = function (level, obj) {
                if (level === "Fibergroup") {
                    $scope.fiberCount = obj.fiberCount;
                } else {
                    $scope.fiberCount = undefined;
                }
                $uibModal.open({
                    size: "md",
                    templateUrl: '/static/templates/dashboard/project/modal/addEditCollectionModal.html',
                    resolve: {
                        level: function () {
                            return level;
                        },
                        obj: function () {
                            return obj;
                        }
                    },
                    controller: ["$scope", "$uibModalInstance", "level", "obj", function ($scope, $uibModalInstance, level, obj) {
                        $scope.mode = "Add";
                        $scope.level = level;
                        $scope.obj = obj;
                        $scope.name = "";
                        $scope.saveCollection = function () {
                            if (_.isEmpty($scope.name)) {
                                return;
                            }
                            $uibModalInstance.close($scope.name);
                        };
                    }]
                }).result.then(function (collectionName) {
                    var config = {
                        orgId: $scope.orgId,
                        projectId: $scope.projectId,
                        name: collectionName,
                        targetType: level,
                        targetId: obj.id
                    };
                    return aerosApi.createAttachmentCollection(config).then(function (rtn) {
                        //console.log('Create Collection RTN', rtn);
                        Notification.success("Successfully create file collection.");
                        $scope.loadProject();
                        $scope.viewFileCollection(level, rtn.data.collection, obj);
                    }, function (err) {
                        //console.log('Create Collection ERR', err);
                        Notification.error("There was a problem creating the file collection.");
                    });
                });
            };
            $scope.deleteFileCollection = function (collection, fiberGroup) {
                if (!$scope.allow["configureProjects"]) return;
                $uibModal.open({
                    size: "md",
                    templateUrl: '/static/templates/dashboard/project/modal/confirmDeleteModal.html',
                    resolve: {
                        collection: function () {
                            return collection;
                        }
                    },
                    controller: ["$scope", "collection", function ($scope, collection) {
                        $scope.entity = "File Collection";
                        $scope.list = [collection];
                        $scope.warning = "This will permanently delete all files in the collection.";
                    }]
                }).result.then(function () {
                    var config = {
                        orgId: $scope.orgId,
                        projectId: $scope.projectId,
                        collectionId: collection.id
                    };
                    if (fiberGroup) {
                        config.fiberGroupId = fiberGroup.id;
                    }
                    return aerosApi.deleteAttachmentCollection(config).then(function (rtn) {
                        Notification.success("Successfully deleted the file collection.");
                        $scope.loadProject();
                    }, function (err) {
                        Notification.error("There was a problem deleting the file collection.");
                    });
                });
            };

            $scope.noFiberGroupsExist = function () {
                var rtn = true;
                if ($scope.project.fiberGroups && $scope.project.fiberGroups.length) {
                    rtn = false;
                }
                return rtn;
            };

            $scope.changeTopToolBelt = function (event) {
                $scope.topModalToolBelt = event.currentTarget.parentElement.getBoundingClientRect().top + 60;
            };

            $scope.changeTopToolBox = function (event) {
                $scope.topModalToolBox = event.currentTarget.parentElement.getBoundingClientRect().top + 60;
            };

            function showUnmarkDeleteDialog(fg) {
                unmarkDeleteFiberGroupModal.open(fg, $scope.projectId, $scope.organization.id).then(
                    function (result) {

                        switch (result.action) {
                            case 'unmark':
                                Notification.success("Fiber Group unmarked successfully");
                                $scope.loadProject(true);
                                break;
                            case 'delete':
                                Notification.success("Fiber Group deleted successfully");
                                $scope.loadProject();
                                break;

                        }
                    }
                );
            }

            $scope.selectAllNone = function selectAllNone() {

                if (FiberGroupService.selectedFiberGroups.length > 0) {
                    $scope.selectNone();
                } else {
                    $scope.selectAll();
                }
            };

            function findInstructionByAction(action) {
                return instructions[routesConstant.CUSTOMER.PROJECTS_PROJECT_FIBERGROUPS.stateName]
                    .actions.find(function (element) {
                        return element.action && element.action.toLowerCase() === action.toLowerCase();
                    });
            }

            (function setActions() {
                $scope.actions = [{
                    label: function label(project) {
                        return "Generate Sample Results";
                    },
                    icon: function icon(project) {
                        return 'fa-bolt';
                    },
                    if: function (project) {
                        return $scope.allow.sampleResults && $scope.allow.configureProjects;
                    },
                    disabled: function (project) {
                        return !$scope.hasSelectedFiberGroups();
                    },
                    action: function (project) {
                        return $scope.generateTestResults();
                    }
                }, {
                    label: function label(project) {
                        return findInstructionByAction("Select FGs").instructions;
                    },
                    icon: function icon(project) {
                        if (FiberGroupService.selectedFiberGroups.length == 0) {
                            return 'fa-square-o';
                        }

                        if (FiberGroupService.selectedFiberGroups.length === project.fiberGroups.length) {
                            return 'fa-check-square-o';
                        }

                        if (FiberGroupService.selectedFiberGroups.length < project.fiberGroups.length
                            && FiberGroupService.selectedFiberGroups.length > 0) {
                            return 'fa-check-square-o fg-color-grey';
                        }
                    },
                    if: function (project) {
                        return true;
                    },
                    disabled: function (project) {
                        return false;
                    },
                    action: function (project) {
                        return $scope.selectAllNone();
                    }
                }, {
                    label: function label(project) {
                        return findInstructionByAction("Notification").instructions;
                    },
                    icon: function icon(project) {
                        return "fa-" + findInstructionByAction("Notification").icon;
                    },
                    if: function (project) {
                        return $scope.allow.notifyTeam;
                    },
                    disabled: function (project) {
                        return false;
                    },
                    action: function (project) {
                        return $scope.notifyTeamMembers();
                    }
                }, {
                    label: function label(project) {
                        return findInstructionByAction("Progress Details").instructions;
                    },
                    icon: function icon(project) {
                        return "fa-" + findInstructionByAction("Progress Details").icon;
                    },
                    if: function (project) {
                        return $scope.allow.viewProgress;
                    },
                    disabled: function (project) {
                        return false;
                    },
                    action: function (project) {
                        return $scope.toggleReveal();
                    }
                }, {
                    label: function label(project) {
                        return findInstructionByAction("Delete Fibergroups").instructions;
                    },
                    icon: function icon(project) {
                        return "fa-" + findInstructionByAction("Delete Fibergroups").icon;
                    },
                    if: function (project) {
                        return ($scope.$parent.accountRoles == "Professional") && $scope.allow.configureProjects;
                    },
                    disabled: function (project) {
                        return !$scope.hasSelectedFiberGroups();
                    },
                    action: function (project) {
                        return $scope.deleteSelectedFiberGroups();
                    }
                }, {
                    label: function label(project) {
                        return findInstructionByAction("Copy Fibergroups").instructions;
                    },
                    icon: function icon(project) {
                        return "fa-" + findInstructionByAction("Copy Fibergroups").icon;
                    },
                    if: function (project) {
                        return ($scope.$parent.accountRoles == "Professional") && $scope.allow.configureProjects && (project.originator==='aeros' || $scope.allow.editGemini);
                    },
                    disabled: function (project) {
                        return !$scope.hasSelectedFiberGroups() || $scope.copyFgProgress;
                    },
                    action: function (project) {
                        return $scope.duplicateSelectedFiberGroups();
                    }

                }, {
                    label: function label(project) {
                        return findInstructionByAction("Create New Fibergroup").instructions;
                    },
                    icon: function icon(project) {
                        return "fa-" + findInstructionByAction("Create New Fibergroup").icon;
                    },
                    if: function (project) {
                        return ($scope.$parent.accountRoles == "Professional") && $scope.allow.configureProjects && (project.originator === 'aeros' || $scope.allow.editGemini);
                    },
                    disabled: function (project) {
                        return false;
                    },
                    action: function (project) {
                        return $scope.showCreateFiberGroupModal();
                    }

                }];

                $scope.uploadAction = {
                    label: function label(project) {
                        return "Create File Collection for Project '" + project.name + "'";
                    },
                    icon: function icon(project) {
                        if (project.status === 'Archived' || project.status === 'Hidden') {
                            return "fa-upload disabled";
                        }

                        return "fa-upload";
                    },
                    if: function (project) {
                        return $scope.allow.editProjects && (project.originator==='aeros' || $scope.allow.editGemini);
                    },
                    disabled: function (project) {
                        return false;
                    },
                    action: function (project) {
                        return $scope.createFileCollection('Project', project);
                    }

                };

                $scope.removeTestSetupFromFiberGroupAction = {
                    label: function label() {
                        return findInstructionByAction("Remove Test Setup").instructions;
                    },
                    if: function () {
                        return $scope.allow.configureProjects && ($scope.project.originator==='aeros'
                            || $scope.allow.editGemini);
                    },
                    action: function action(testSetup, fiberGroup) {
                        return $scope.removeTestSetupFromFiberGroup(testSetup, fiberGroup);
                    }
                };

                $scope.removeToolFromFiberGroupAction = {
                    label: function label() {
                        return findInstructionByAction("Remove Test Setup").instructions;
                    },
                    if: function () {
                        return $scope.allow.configureProjects && ($scope.project.originator==='aeros'
                            || $scope.allow.editGemini);
                    },
                    action:function action(tool, fiberGroup) {
                        $scope.removeToolFromFiberGroup(tool, fiberGroup);
                    }
                };

                $scope.fgActions = [{
                    label: function label(project) {
                        return findInstructionByAction("Upload").instructions;
                    },
                    icon: function icon(fiberGroup) {
                        return "fa-" + findInstructionByAction("Upload").icon;
                    },
                    if: function (fiberGroup) {
                        return $scope.allow.configureProjects && (project.originator === 'aeros' || $scope.allow.editGemini);
                    },
                    disabled: function (fiberGroup) {
                        return false;
                    },
                    action: function (fiberGroup) {
                        return $scope.createFileCollection('Fibergroup', fiberGroup);
                    }
                }, {
                    label: function label(fiberGroup) {
                        return findInstructionByAction("Edit Fibergroup").instructions;
                    },
                    icon: function icon(fiberGroup) {
                        return "fa-" + findInstructionByAction("Edit Fibergroup").icon;
                    },
                    if: function (project) {
                        return $scope.allow.configureProjects && (project.originator === 'aeros' || $scope.allow.editGemini);
                    },
                    disabled: function (fiberGroup) {
                        return false;
                    },
                    action: function (fiberGroup) {
                        return $scope.editFiberGroup(fiberGroup);
                    }
                }, {
                    label: function label(fiberGroup) {
                        return findInstructionByAction("Info").instructions;
                    },
                    icon: function icon(fiberGroup) {
                        return "fa-" + findInstructionByAction("Info").icon;
                    },
                    if: function (fiberGroup) {
                        return true;
                    },
                    disabled: function (fiberGroup) {
                        return false;
                    },
                    action: function (fiberGroup) {
                        return $scope.showFiberGroupInfo(fiberGroup);
                    }
                }, {
                    label: function label(fiberGroup) {
                        return findInstructionByAction("Results").instructions;
                    },
                    icon: function icon(fiberGroup) {
                        return "fa-" + findInstructionByAction("Results").icon;
                    },
                    if: function (fiberGroup) {
                        return true;
                    },
                    disabled: function (fiberGroup) {
                        return false;
                    },
                    action: function (fiberGroup) {
                        return $scope.showDetailedResult(fiberGroup, 'All');
                    }
                }];
            })();
        });
    }

    function FeatureAvailable() {
        return function (items, scope) {
            return _.filter(items, function (setup) {
                return _.includes(scope.features, setup.type.toLowerCase());
            });
        };
    }

    function IsNewTool() {
        return function (appliedTools, scope) {
            return _.filter(appliedTools, function (appliedTool) {
                return _.includes(['otdr'], appliedTool.toolType.toLowerCase());
            });
        };
    }

})();


